import React, { Component } from "react";
import { FiGithub, FiMail, FiTwitter, FiInstagram } from "react-icons/fi";

class App extends Component {
  render() {
    return (
      <div className="App">
        <header id="home">
          <div className="row banner">
            <div className="banner-text">
              <h1
                className="responsive-headline"
                style={{ "font-variant": "small-caps" }}
              >
                Annapolis Valley Software
              </h1>
              <h3 style={{ "color": "#006E33"}}>
                A small SaaS start up company with big ambitions to help better
                the world.
              </h3>
              <hr />
              <ul className="social">
                <li key="github">
                  <a
                    href="https://github.com/AnnapolisValleySoftware/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FiGithub />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default App;
